import { useStaticQuery, graphql } from "gatsby"

export const usePrivacyQuery = () => {


    const data = useStaticQuery(graphql`
    query PrivacyQuery {
        wpPage(title: {eq: "PRIVACY"}) {
            ACF_PrivacyPage {
                privacyHeader {
                    image{
                        publicUrl
                    }
                    title
                }
                privacyContent {
                    content
                }
                
                privacyMetaData {
                    metaTitle
                    metaDescription
                }
        }
        }
    }     
    `)
    return data.wpPage.ACF_PrivacyPage;
}