import * as React from "react";
import Layout from "../components/global/Layout";
import Hero from "../components/privacy/Hero";
import MainContent from "../components/privacy/MainContent";
import { usePrivacyQuery } from "../hooks/usePrivacyQuery";
import Helmet from "react-helmet";

const PrivacyPage = () => {
    const data = usePrivacyQuery();
    return (
        <Layout>
            <Hero data={data.privacyHeader} />
            <MainContent data={data.privacyContent} />
            <Helmet>
                <title>{data.privacyMetaData.metaTitle}</title>
                <meta name="description" content={data.privacyMetaData.metaDescription} />
            </Helmet>
        </Layout>
    )
}

export default PrivacyPage;